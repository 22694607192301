import React from "react";
import AddressAndTransport from "../components/AddressAndTransport";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import {
	Button,
	Col,
	Container,
	Dropdown,
	Form,
	Row,
	Stack,
	DropdownButton,
} from "react-bootstrap";
import { parseNestedHTML } from "../Utils";
import HTMLReactParser from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import NinjaStarSVG from "../components/svg/NinjaStarSVG";
import { ValidationError } from "@formspree/react";
import SafeHtmlParser from "../components/safe-html-parser";
import ReactPlayer from "react-player";
import DesktopLogo from "../images/xmas.svg";
import { MdOpacity } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import ReviewSection from "../components/review-section";
import ContactForm from "../components/contactForm";
import XmasContactForm from "../components/xmas-contact-form";

const NinjaChristmasParties = () => {
	const data = useStaticQuery(graphql`
		query {
			wpChristmasParties {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				christmasPartyFields {
					heroSection {
						title
						description
						date
						buttonText
						backgroundImage {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					pricing {
						title
						underTitleText
						price
						services {
							label
						}
					}
					reviews {
						buttonText
						title
					}
					smallBanner {
						leftText
						rightText
					}
					socialBannerTitle
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const NBPPage = data.wpChristmasParties.christmasPartyFields;
	const seoFields = data.wpChristmasParties.seoFields;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Ninja Christmas Parties",
				item: {
					url: `${siteUrl}/ninja-christmas-parties`,
					id: `${siteUrl}/ninja-christmas-parties`,
				},
			},
		],
	};
	return (
		<div>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/ninja-christmas-parties`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<section className="position-relative ">
					<div className="position-relative hero-carousal">
						<div
							style={{
								zIndex: 1,
								background:
									"linear-gradient(180deg, rgba(191, 0, 43, 0.80) 0%, rgba(89, 0, 20, 0.80) 100%)",
								borderTop: "1px solid #FFF",
								borderBottom: "1px solid #FFF",
							}}
							className="position-absolute h-100 w-100"
						>
							<img
								src={DesktopLogo}
								style={{ zIndex: -4, opacity: 0.5 }}
								alt="Xmas"
								className="position-absolute bottom-0 start-0 w-100"
							/>

							<Container className="h-100">
								<Row className="align-items-center justify-content-center h-100">
									<Col lg={10} xl={8} className="text-center">
										<div className="h-100 ">
											<div className="stylishText text-uppercase text-center center-text ">
												<SafeHtmlParser
													htmlContent={NBPPage.heroSection.title}
												/>
											</div>
											<p className="text-white fs-5 pb-0 mb-0">
												{NBPPage.heroSection.description}
											</p>
											<Button
												variant="white"
												href="#form"
												className="text-primary text-uppercase  my-3 my-lg-4 fs-5 px-xl-4 py-2"
											>
												{NBPPage.heroSection.buttonText}
											</Button>
											<p className="text-white">{NBPPage.heroSection.date}</p>
										</div>
									</Col>
								</Row>
							</Container>
						</div>

						<div className="slider-container">
							<GatsbyImage
								className="images-height"
								image={
									NBPPage.heroSection.backgroundImage.node.localFile
										?.childImageSharp?.gatsbyImageData
								}
								style={{
									objectFit: "cover",
									width: "100%",
								}}
								alt={NBPPage.heroSection.backgroundImage.node?.altText}
							/>
						</div>
					</div>
				</section>

				<section className="mt-5 pb-4 pb-lg-7">
					<Container>
						<Row>
							<Col>
								<div>
									<h2 className="display-5 text-center py-3 py-md-5  text-uppercase">
										Packages
									</h2>
								</div>
							</Col>
						</Row>
						<Row className="gy-4">
							{NBPPage.pricing.map(
								({ image, title, price, services, underTitleText }, i) => (
									<Col lg={4} key={i}>
										<div
											className="p-3 h-100"
											style={{ backgroundColor: "#181819", borderRadius: 8 }}
										>
											<GatsbyImage
												image={
													image?.node.localFile?.childImageSharp
														?.gatsbyImageData
												}
												style={{ objectFit: "contain", borderRadius: 12 }}
												alt={image?.node?.altText}
											/>
											<h3 className="fs-2 mt-3  text-uppercase">{title}</h3>
											{underTitleText && (
												<p className="pb-0 mb-0 text-white">{underTitleText}</p>
											)}
											<p className="text-white pt-3 pb-0 fs-5 karla-bold">
												{price}
											</p>
											<div className="d-md-none">
												{services.map(({ label }, i) => (
													<Stack
														direction="horizontal"
														className="align-items-start"
														gap={1}
														key={i}
													>
														<NinjaStarSVG
															style={{ minWidth: "15px", maxWidth: "15px" }}
														/>
														<p className="text-white pt-1">{label}</p>
													</Stack>
												))}
											</div>
											<div className="d-none d-md-block">
												{services.map(({ label }, i) => (
													<Stack
														direction="horizontal"
														className="align-items-start"
														gap={2}
														key={i}
													>
														<NinjaStarSVG
															style={{ minWidth: "20px", maxWidth: "20px" }}
														/>
														<p className="text-white pt-1">{label}</p>
													</Stack>
												))}
											</div>
										</div>
									</Col>
								)
							)}
						</Row>
					</Container>
				</section>
				<section className="bg-white py-3">
					<Container>
						<Row>
							<Col lg={6}>
								{" "}
								<p className="py-lg-0 text-center text-md-center my-lg-0 text-primary karla-bold">
									{NBPPage.smallBanner.leftText}
								</p>
							</Col>
							<Col className="text-lg-end" lg={6}>
								{" "}
								<p className="py-lg-0 text-center text-md-center my-lg-0 pb-0 mb-0 text-primary karla-bold">
									{NBPPage.smallBanner.rightText}
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<div
						className="py-3"
						style={{
							backgroundColor: "#BF002B",
						}}
					>
						<Container>
							<Row>
								<Col
									lg={6}
									className="position-relative text-center p-0 session3"
								>
									<Stack
										direction="horizontal"
										gap={3}
										className="align-items-center d-none d-md-flex justify-content-center justify-content-md-start "
									>
										<div className="d-flex align-items-center">
											<a
												target="_blank"
												rel="noreferrer"
												className="white-link-black  d-inline-block me-3  fs-2 social-icon"
												href="https://www.instagram.com/ninjaeastbourne/"
											>
												<AiFillInstagram />
											</a>
											<a
												target="_blank"
												rel="noreferrer"
												className="white-link-black me-xl-4 d-inline-block   fs-2 social-icon"
												href="https://www.facebook.com/NinjaArenaEastbourne"
											>
												<FaFacebookSquare />
											</a>
										</div>
										<div className="title stylishText">
											<SafeHtmlParser htmlContent={NBPPage.socialBannerTitle} />
										</div>
									</Stack>
									<Stack
										direction="horizontal"
										gap={3}
										className="align-items-start d-md-none justify-content-center justify-content-md-start "
									>
										<div className="title stylishText position-relative">
											<SafeHtmlParser htmlContent={NBPPage.socialBannerTitle} />
											<div className="d-flex me-3 position-absolute top-0 end-0 align-items-center">
												<a
													target="_blank"
													rel="noreferrer"
													className="white-link-black  d-inline-block me-3  fs-2 social-icon"
													href="https://www.instagram.com/ninjaeastbourne/"
												>
													<AiFillInstagram />
												</a>
												<a
													target="_blank"
													rel="noreferrer"
													className="white-link-black me-xl-4 d-inline-block   fs-2 social-icon"
													href="https://www.facebook.com/NinjaArenaEastbourne"
												>
													<FaFacebookSquare />
												</a>
											</div>
										</div>
									</Stack>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
				<section className="pt-5 pt-lg-7">
					<Container>
						<h2 className="display-5 pb-4 text-center text-uppercase">
							{NBPPage.reviews.title}
						</h2>

						<ReviewSection fromIndex={0} toIndex={6} black />
						<div className="flex d-flex justify-content-center m-3">
							<Button
								target="_blank"
								rel="noreferrer"
								href="https://www.google.com/maps/place/Ninja+Arena,+Eastbourne/@50.769601,0.28568,14z/data=!4m8!3m7!1s0x47df7341975be679:0x63c07fe02c3335f3!8m2!3d50.7696006!4d0.2856804!9m1!1b1!16s%2Fg%2F11t5y526t4?hl=en&entry=ttu"
								className="text-white w-100 w-md-auto text-uppercase mt-4 fs-5 px-4 py-2"
							>
								{NBPPage.reviews.buttonText}
							</Button>
						</div>
					</Container>
				</section>
				<section id="form" className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 pb-3 text-uppercase text-center">
									enquire now
								</h2>

								<XmasContactForm />
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</div>
	);
};

export default NinjaChristmasParties;
